// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { prepareClient, SITEWIDE_CONTENT } from "@telia/b2x-graphql-request";

type ContentModelItem = { id: string; query: string };

type ContentModel<T> = Record<keyof T, ContentModelItem[] | ContentModelItem>;

// TODO: improve type safety
class B2xContentFactory {
  private contentClient: ReturnType<typeof prepareClient>;

  public constructor(apiBasePath: string) {
    this.contentClient = prepareClient(apiBasePath, SITEWIDE_CONTENT);
  }

  public async createContent<T>(contentModel: ContentModel<T>): Promise<{ [K in keyof T]: T[K] }> {
    const requestsBuffer: Promise<void>[] = [];
    const content = {} as T;

    Object.entries(contentModel).forEach(([key, contentUnits]) => {
      Array.isArray(contentUnits)
        ? this.handleMultipleContentUnits(requestsBuffer, contentUnits, (queryResult) => {
            Object.assign(content, {
              [key]: [...(<Array<T[keyof T]>>(content[key as keyof T] || [])), queryResult],
            });
          })
        : this.handleSingleContentUnit(
            requestsBuffer,
            <ContentModelItem>contentUnits,
            (queryResult) => Object.assign(content, { [key]: queryResult })
          );
    });

    await Promise.all(requestsBuffer).catch((error) => {
      throw error;
    });

    return content;
  }

  private handleMultipleContentUnits(
    requestsBuffer: Promise<void>[],
    contentUnits: ContentModelItem[],
    onSuccess: (queryResult: unknown) => void
  ) {
    contentUnits.forEach(async (contentUnit) => {
      this.handleSingleContentUnit(requestsBuffer, contentUnit, onSuccess);
    });
  }

  private handleSingleContentUnit(
    requestsBuffer: Promise<void>[],
    contentUnit: ContentModelItem,
    onSuccess: (queryResult: unknown) => void
  ) {
    const request = this.requestContent(contentUnit.query, contentUnit.id, onSuccess);
    requestsBuffer.push(request);
  }

  private requestContent(query: string, id: string, onSuccess: (queryResult: unknown) => void) {
    return new Promise<void>((resolve) => {
      this.contentClient
        .request(query, {
          id: id,
        })
        .then((queryResult) => {
          onSuccess(queryResult);
          resolve();
        })
        .catch(() => {
          resolve();
        });
    });
  }
}

export { B2xContentFactory };
